import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import contentfulImage from 'lib/contentful-image'
import contentfulUrlForLink from 'lib/contentful-url-for-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

const Footer = props => {
  return (
    <footer className="hm-footer">
      <div className="hm-footer__wrapper">
        <div className="hm-footer__section logo-collection">
          {props.logos.map((logo, i) => {
            const imageUrl = contentfulImage(logo.file.url, {
              width: 150,
            })

            return (
              <div key={`${logo.id}-${i}`} className="logo-collection--logo">
                <img src={imageUrl} alt={logo.file.description} />
              </div>
            )
          })}
        </div>

        <div className="hm-footer__section hm-footer__nav flex flex-wrap">
          {props.linkGroups.map(linkGroup => {
            if (!linkGroup.links) return null

            return (
              <ul
                key={linkGroup.id}
                className="hm-footer__list hm-footer__list--links"
              >
                {linkGroup.links.map(link => {
                  const url = contentfulUrlForLink(link)
                  return (
                    <li
                      key={`footer-${linkGroup.id}-${link.id}`}
                      className="list--item"
                    >
                      <Link to={url}>{link.label}</Link>
                    </li>
                  )
                })}
              </ul>
            )
          })}
        </div>
      </div>

      <div className="hm-footer__bottombar">
        <div className="hm-footer__wrapper">
          <div className="hm-footer__brand">
            <p className="hm-footer__brand-name">Heizungsmacher</p>
            <p className="hm-footer__brand-info">
              eine Marke von Heizungsmacher AG
            </p>
          </div>
          <ul className="hm-footer__list hm-footer__list--locations">
            {props.addresses.map((address, i) => {
              const splitAddress = address.split(',')
              return (
                <li className="list--item" key={`footer-address-${i}`}>
                  <div>
                    {splitAddress[0]}
                    <br />
                    {splitAddress[1]}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div className="hm-footer__bottombar">
        <div className="hm-footer__wrapper">
          <ul className="hm-footer__list hm-footer__list--locations hm-footer__list--social">
            <li className="list--item">
              <Link
                to="https://facebook.com/heizungsmacher"
                className="link link--primary"
              >
                <FontAwesomeIcon icon={faFacebookF} alt="foobar" />
              </Link>
            </li>
            <li className="list--item">
              <Link to="https://instagram.com/heizungsmacher" className="link">
                <FontAwesomeIcon icon={faInstagram} />
              </Link>
            </li>
            <li className="list--item">
              <Link
                to="https://linkedin.com/company/heizungsmacher"
                className="link"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </Link>
            </li>
          </ul>
          <a
            href="#"
            className="mt3 hm-footer__consent-link"
            onClick={e => {
              e.preventDefault()
              window.displayPreferenceModal()
            }}
            id="termly-consent-preferences"
          >
            Cookie Einstellungen
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer

Footer.propTypes = {
  logos: PropTypes.array,
  linkGroups: PropTypes.array,
  addresses: PropTypes.arrayOf(PropTypes.string),
}
