import React from 'react'
import PropTypes from 'prop-types'
import { urlLocationPropType } from 'lib/prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Navigation from 'components/Contentful/Navigation'
import { config } from '@fortawesome/fontawesome-svg-core'
import '../../styles/style.scss'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Footer from 'components/Contentful/Footer'

config.autoAddCss = false

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query indexQuery {
          site {
            siteMetadata {
              title
              siteUrl
            }
          }
          allContentfulFooter {
            edges {
              node {
                logos {
                  id
                  file {
                    url
                  }
                }
                addresses
                linkGroups {
                  id
                  links {
                    id
                    label
                    url
                    page {
                      slug
                    }
                    anchor
                  }
                }
              }
            }
          }

          allContentfulNavigation {
            edges {
              node {
                links {
                  id
                  page {
                    slug
                    homepage
                  }
                  url
                  # Can't use right now
                  # anchor
                  label
                }
                name
              }
            }
          }

          allContentfulPage {
            edges {
              node {
                identifier
                homepage
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const navigation = data.allContentfulNavigation.edges.find(
          edge => edge.node.name === 'Hauptmenü'
        )
        const links = (navigation || data.allContentfulNavigation.edges[0]).node
          .links
        const footer = data.allContentfulFooter.edges[0].node
        const pages = data.allContentfulPage.edges

        return (
          <div>
            <Helmet
              defaultTitle={data.site.siteMetadata.title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            >
              <meta name="supported-color-schemes" content="normal" />
            </Helmet>
            <div className="page-wrapper">
              <Navigation links={links} pages={pages} location={location} />
              <div className="main-content">{children}</div>
              <Footer
                logos={footer.logos}
                addresses={footer.addresses}
                linkGroups={footer.linkGroups}
              />
            </div>
          </div>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  location: urlLocationPropType,
}

export default Layout
