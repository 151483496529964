import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

const SiteMetadata = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              siteUrl
              mailContact
              pardotEndpoint
              googleApiKey
            }
          }
        }
      `}
      render={data => props.render(data)}
    />
  )
}

export default SiteMetadata

SiteMetadata.propTypes = {
  render: PropTypes.func,
}
