const getStore = clearAfterSession => {
  try {
    const store = clearAfterSession ? sessionStorage : localStorage

    // try accessing the store to catch Safari Private Mode issues
    store.setItem('test', 'test')
    store.removeItem('test')

    return store
  } catch (e) {
    window.__store = window.__store || {}
    return {
      setItem(identifier, data) {
        window.__store[identifier] = data
      },
      getItem(identifier) {
        return window.__store[identifier]
      },
      removeItem(identifier) {
        delete window.__store[identifier]
      },
    }
  }
}

const createStore = (identifier, clearAfterSession = true) => {
  if (typeof window === 'undefined') {
    return {
      save() {},
      load() {},
      clear() {},
    }
  }

  const store = getStore(clearAfterSession)

  return {
    save(data) {
      store.setItem(identifier, JSON.stringify(data))
    },

    load() {
      try {
        return JSON.parse(store.getItem(identifier))
      } catch (e) {
        return
      }
    },

    clear() {
      store.removeItem(identifier)
    },
  }
}

export default createStore
