const paramNames = {
  w: 'w',
  width: 'w',
  height: 'h',
  h: 'h',
  quality: 'q',
  q: 'q',
  format: 'fm',
  fm: 'fm',
}

function toParam(object) {
  return Object.keys(object)
    .map(key => `${paramNames[key]}=${object[key]}`)
    .join('&')
}

export default function contentfulImage(url, resolutions = {}) {
  return `${url}?${toParam(resolutions)}`
}
