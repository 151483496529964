export default function contentfulUrlForLink(link) {
  let url = ''
  if (link.page) {
    url = link.page.homepage ? '/' : `/${link.page.slug}`
  } else {
    url = link.url
  }

  if (link.anchor) {
    url += `#${link.anchor}`
  }

  return encodeURI(url)
}
