import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ children, to, ...other }) => {
  const internal =
    typeof to === 'string' && !to.startsWith('http') && !to.startsWith('//')

  // Use gatsby-link for internal links, and <a> for others
  if (internal) {
    // make sure it has a leading and a trailing slash
    const target =
      (to.startsWith('/') ? '' : '/') + to + (to.endsWith('/') ? '' : '/')

    return (
      <GatsbyLink to={target} {...other}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} rel="noopener" {...other}>
      {children}
    </a>
  )
}

export default Link

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
