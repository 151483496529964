import PropTypes from 'prop-types'

const category = PropTypes.oneOfType([
  PropTypes.shape({ name: PropTypes.string }),
  PropTypes.shape({ value: PropTypes.string }),
])
category.childCategories = PropTypes.arrayOf(category)

const formItem = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  }),
])

export const filePropType = PropTypes.shape({
  versionId: PropTypes.string.isRequired,
  type: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string,
  modified: PropTypes.string,
  isHidden: PropTypes.bool.isRequired,
  documentId: PropTypes.string.isRequired,
  description: PropTypes.string,
})

export const mediaPropType = PropTypes.shape({
  title: PropTypes.string,
  fileType: PropTypes.string,
  altText: PropTypes.string,
  file: PropTypes.shape({
    url: PropTypes.string,
  }),
  description: PropTypes.string,
})

const productImagePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
})

export const productVariantPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.number,
  priceWork: PropTypes.number,
  priceMaterial: PropTypes.number,
  manufacturer: PropTypes.string,
  inCategories: PropTypes.arrayOf(category),
  description: PropTypes.string,
  isAvailableOnline: PropTypes.bool,
  defaultImage: productImagePropType,
  additionalImages: PropTypes.arrayOf(productImagePropType),
  metaProduct: PropTypes.string,
  isOption: PropTypes.bool,
  isOptional: PropTypes.bool,
  files: PropTypes.arrayOf(filePropType),
})

export const articlePropType = PropTypes.shape({
  text: PropTypes.string,
  gallery: PropTypes.arrayOf(mediaPropType),
})

export const configurationPropType = PropTypes.shape({
  productCategories: PropTypes.arrayOf(category),
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
})

export const configuratorFormPropType = PropTypes.shape({
  currentTypeOfHeating: formItem.isRequired,
  declaredEnergyConsumption: PropTypes.string.isRequired,
  energyConsumptionUnits: formItem.isRequired,
  heatDistributionSystem: formItem.isRequired,
  waterHeating: formItem.isRequired,
  numberOfPeople: PropTypes.string.isRequired,
})

export const contactPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  notes: PropTypes.string,
})

export const FAQPropType = PropTypes.shape({
  title: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
})

export const howToPropType = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape({ url: PropTypes.string }),
})

export const knowledgeArticlePropType = PropTypes.shape({
  title: PropTypes.string,
  articleType: PropTypes.string,
  keyword: PropTypes.string,
  description: PropTypes.string,
  updatedAt: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.oneOfType([articlePropType, FAQPropType, howToPropType])
  ),
  topic: PropTypes.shape({
    label: PropTypes.string,
    page: PropTypes.shape({ slug: PropTypes.string }),
  }),
  previousArticle: PropTypes.string,
  nextArticle: PropTypes.string,
})

export const linkPropType = PropTypes.shape({
  label: PropTypes.string,
  page: PropTypes.shape({
    slug: PropTypes.string,
  }),
  url: PropTypes.string,
  anchor: PropTypes.string,
  type: PropTypes.oneOf(['link']),
})

export const locationPropType = PropTypes.shape({
  street: PropTypes.string.isRequired,
  zipCode: PropTypes.number.isRequired,
  locality: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
})

export const metaProductPropType = PropTypes.shape({
  unit: PropTypes.string,
  productVariants: PropTypes.arrayOf(productVariantPropType).isRequired,
  defaultProductVariant: PropTypes.string,
})

export const productPropType = PropTypes.shape({
  metaProducts: PropTypes.arrayOf(metaProductPropType).isRequired,
})

export const siteDataPropType = PropTypes.shape({
  site: PropTypes.shape({
    siteMetaData: PropTypes.shape({
      title: PropTypes.string,
      siteUrl: PropTypes.string,
      pardotEndpoint: PropTypes.string,
      mailContact: PropTypes.string,
      googleApiKey: PropTypes.string,
    }),
  }),
})

export const urlLocationPropType = PropTypes.shape({
  host: PropTypes.string,
  hostname: PropTypes.string,
  href: PropTypes.string,
  origin: PropTypes.string,
  pathname: PropTypes.string,
  port: PropTypes.string,
  protocol: PropTypes.string,
  search: PropTypes.string,
  reload: PropTypes.func,
  replace: PropTypes.func,
  tostring: PropTypes.func,
})

export const roofPropType = PropTypes.shape({
  attributes: PropTypes.shape({
    ausrichtung: PropTypes.number,
    flaeche: PropTypes.number,
    neigung: PropTypes.number,
  }),
  bbox: PropTypes.arrayOf(PropTypes.number),
  featureId: PropTypes.number,
  geometry: PropTypes.shape({
    rings: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    ),
  }),
  id: PropTypes.number,
  layerName: PropTypes.string,
})
