import React from 'react'
import PropTypes from 'prop-types'
import {
  linkPropType,
  siteDataPropType,
  urlLocationPropType,
} from 'lib/prop-types'
import Link from 'components/Link'
import logo from 'assets/hm-logo-blue.svg'
import navicon from './nav-icon.svg'
import closeIcon from './close-icon.svg'
import contentfulUrlForLink from 'lib/contentful-url-for-link'
import { phoneNumber, callablePhoneNumber } from 'lib/phone-number'
import SiteMetadata from 'lib/SiteMetadata'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressBook,
  faPhone,
  faEnvelope,
  faSun,
  faFireFlameCurved,
} from '@fortawesome/free-solid-svg-icons'
import createStore from 'lib/util/create-store.js'

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }

    const offerPage = props.pages.find(
      ({ node }) => node.identifier === 'offerform'
    )

    this.offerUrl = `/${offerPage.node.slug}`
    this.siteMetadata = props.data.site.siteMetadata
  }

  toggleNavigation() {
    this.setState({ open: !this.state.open })
  }

  hideNavigation() {
    this.setState({ open: false })
  }

  renderPencil(page) {
    const configurationStore = createStore('configuration')
    const solarcheckStore = createStore('solarcheck')
    const optionsStore = createStore('options')
    const contactStore = createStore('contact')

    let showPencil

    switch (page) {
      case 'configurator':
        showPencil =
          Boolean(configurationStore.load()) || Boolean(solarcheckStore.load())
        break
      case 'formular':
        showPencil =
          Boolean(configurationStore.load()) ||
          Boolean(optionsStore.load()) ||
          Boolean(contactStore.load())
        break
      case 'consulting':
        showPencil = Boolean(contactStore.load())
        break
      case 'heizungscheck':
        showPencil = Boolean(configurationStore.load())
        break
      case 'solarcheck':
        showPencil = Boolean(solarcheckStore.load())
        break
    }

    if (showPencil) {
      return <div className="nav__action--badge" />
    }
  }

  render() {
    let className = 'header'
    if (this.state.open) className += ' open'
    const currentPage =
      this.props.location && this.props.location.pathname.replace(/$\/|^\//, '') // remove leading and trailing slashes

    return (
      <header className={className}>
        <div className="section top-bar">
          <div className="section__inner top-bar--inner">
            <div className="top-bar__item">
              <a href={`tel:${callablePhoneNumber}`} className="top-bar--link">
                <FontAwesomeIcon icon={faPhone} className="top-bar__icon" />
                <span className="top-bar__item--content">{phoneNumber}</span>
              </a>
            </div>
            <div className="top-bar__item">
              <a
                href="mailto:info@heizungsmacher.ch"
                target="_blank"
                rel="noreferrer"
                className="top-bar--link"
              >
                <FontAwesomeIcon icon={faEnvelope} className="top-bar__icon" />
                <span className="top-bar__item--content">
                  info@heizungsmacher.ch
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="nav-bar">
          <div className="nav-bar--inner">
            <nav className="nav__box">
              <div className="nav__logo">
                <Link to={'/'}>
                  <img src={logo} />
                </Link>
              </div>
              <div
                className="nav__menu--btn"
                onClick={() => this.toggleNavigation()}
              >
                <img src={this.state.open ? closeIcon : navicon} />
              </div>
              <div className="nav__list">
                {this.props.links.map(link => {
                  const hasActiveChild =
                    currentPage &&
                    currentPage.startsWith(contentfulUrlForLink(link)) &&
                    currentPage[contentfulUrlForLink(link).length] === '/'
                  return (
                    <Link
                      key={link.id}
                      to={contentfulUrlForLink(link)}
                      activeClassName="active"
                      onClick={() => this.hideNavigation()}
                      className={`nav__link ${hasActiveChild ? 'active' : ''}`}
                    >
                      {link.label}
                    </Link>
                  )
                })}
              </div>
            </nav>
            <div className="nav__action-box">
              <div className="nav__action--item-wrap">
                <Link className="nav__action--item">
                  <div className="nav__action--icon">
                    <FontAwesomeIcon icon={faFireFlameCurved} />
                    <FontAwesomeIcon icon={faSun} className="margin--l05" />
                  </div>
                  Online-Check starten
                  {this.renderPencil('configurator')}
                </Link>
                <div className="nav__action--popup">
                  <Link
                    to="/konfigurator"
                    onClick={() => this.hideNavigation()}
                    className="nav__action--item"
                  >
                    <div className="nav__action--icon">
                      <FontAwesomeIcon icon={faFireFlameCurved} />
                    </div>
                    Heizungs-Check
                    {this.renderPencil('heizungscheck')}
                  </Link>
                  <Link
                    to="/photovoltaik-check"
                    onClick={() => this.hideNavigation()}
                    className="nav__action--item"
                  >
                    <div className="nav__action--icon">
                      <FontAwesomeIcon icon={faSun} />
                    </div>
                    Photovoltaik-Check
                    {this.renderPencil('solarcheck')}
                  </Link>
                </div>
              </div>
              <div className="nav__action--item-wrap">
                <Link
                  to="/kontakt"
                  activeClassName="active"
                  onClick={() => this.hideNavigation()}
                  className="nav__action--item"
                >
                  <div className="nav__action--icon">
                    <FontAwesomeIcon icon={faAddressBook} />
                  </div>
                  Kontakt aufnehmen
                  {this.renderPencil('formular')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

const NavigationSite = props => (
  <SiteMetadata render={data => <Navigation data={data} {...props} />} />
)

export default NavigationSite

Navigation.propTypes = {
  links: PropTypes.arrayOf(linkPropType).isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      homepage: PropTypes.bool,
      identifier: PropTypes.string,
    })
  ),
  data: siteDataPropType,
  location: urlLocationPropType,
}
